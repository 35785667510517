import React from "react"
import moment from 'moment';
import { Helmet as ReactHelmet } from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"

import LayoutLight from "../components/LayoutLight"

const Card = styled.div`
    position: relative;

    & > .cover {
        width: 100vw !important;
        max-width: 100%;
        height: auto;
        object-fit: cover !important;
    }

    & > .txt {
        background-color: var(--lightyellow);
        text-align: left;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: -10rem;
        max-width: 530px;
        float: left;
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: 1000;

        @media screen and (max-width: 768px) {
            position: relative;
            margin-top: 1rem;
            padding: 0 10vw 0 10vw;
        }

        & > .title {
            font-size: 1.7rem;
            font-weight: 800;
            text-align: left;
            width: auto;
            margin-top: 0.2rem;
            margin-bottom: 0.5rem;
        }

        & > .subtitle {
            font-size: 1.2rem;
            font-weight: 400;
            margin-bottom: 0.2rem;
        }
    }
`

const Text = styled.div`
  font-size: 1rem;
  padding: 5rem 20vw 0 20vw;
  
  @media screen and (max-width: 768px) {
    padding: 0 10vw 0 10vw;
  }

  h1, h2 {
    font-size: 1.7rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: left;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 1.3rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: left;
    margin-bottom: 40px;
  }

  h5 {
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: left;
    margin-bottom: 40px;
  }

  p {
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    color: rgba(41, 41, 41, 1);
    text-indent: 1.1rem;
    line-height: 1.5;
    letter-spacing: -0.003em;
    word-break: break-word;
    margin-bottom: 2em;
  }

  ul, li {
    line-height: 1.5;
    list-style-position: outside;
    margin-bottom: 2em;
  }

  ol {
    line-height: 1.5;
    list-style-position: outside;
  }

  img {
    width: 100%;
    height: auto;
    align-content: center;
    aspect-ratop
  }
`

export default function BlogPost({ data }) {
    const graphCmsPost = data.graphCmsPost

    return (
        <LayoutLight>
            <ReactHelmet>
                <title>{graphCmsPost.seo.title}</title>
                <meta name="description" content={graphCmsPost.seo.seoDescription} />

                <meta name="og:title" content={graphCmsPost.seo.title} />
                <meta name="og:description" content={graphCmsPost.seo.description}/>
                <meta name="og:image" content={graphCmsPost.cover.url} />
                <meta property="og:type" content="article" />
                <meta property="article:author" content={graphCmsPost.author.name} />
                <meta property="article:published_time" content={graphCmsPost.date} />
                <meta property="article:section" content={graphCmsPost.seo.section} />
                {/* <meta property="article:tag" content="" /> */}
                {/* <meta name="og:url" content='permaculture/blog/${slug}' /> */}
            </ReactHelmet>

            <Card>
                { graphCmsPost.cover &&
                    <img className="cover" src={graphCmsPost.cover.url}/>
                }

                <div className="txt">
                    <h1 className="title">{graphCmsPost.title}</h1>
                    <h3 className="subtitle">par {graphCmsPost.author.name} | {moment(graphCmsPost.date).locale('fr').format('L')}</h3>
                </div>
            </Card>

            <Text>
                <div dangerouslySetInnerHTML={{ __html: graphCmsPost.content.html }} />
            </Text>
        </LayoutLight>
    )
}

export const query = graphql`
  query($slug: String!) {
        graphCmsPost(slug: {eq: $slug}) {
            date
            title
            author { name }
            content { html }
            cover { url }
            seo {
                type
                title
                section
                description
            }
        }
    }
`